<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    alignment?: "start" | "center" | "end";
    avoidCollisions?: boolean;
    sideOffset?: number;
  }>(),
  {
    alignment: "center",
    avoidCollisions: true,
    sideOffset: 15,
  },
);

const isOpen = defineModel('isOpen', {default: false, type: Boolean});

const { alignment, avoidCollisions, sideOffset } = toRefs(props);
</script>
<template>
  <PopoverRoot v-model:open="isOpen">
    <PopoverTrigger>
      <slot />
    </PopoverTrigger>
    <PopoverContent
      :align="alignment"
      :avoidCollisions="avoidCollisions"
      :side-offset="sideOffset ?? 15"
      class="z-10"
    >
      <nav class="w-[300px] min-h-[50px] dark:text-white bg-midnight rounded">
        <div
          class="flex items-center px-4 py-3 bg-[#10151C] justify-between rounded-t w-full top_arrow"
        >
          <div class="flex items-center gap-2 text-xs text-[#788291] w-full">
            <slot name="title" />
          </div>
        </div>
        <div class="w-full p-3 text-sm text-white text-white/80">
          <slot name="content" />
        </div>
      </nav>
    </PopoverContent>
  </PopoverRoot>
</template>
<style>
.top_arrow:after {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #10151c;
    content: "";
    top: -7px;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
}
</style>